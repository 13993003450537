.signature-pad {
  border: 1px solid #000;
  width: 100%;
  height: 220px;
  position: relative;

  canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: $screen-sm-min) {
    height: 400px;
  }

  @media (min-width: $screen-lg-min) {
    height: 450px;
  }
}

.signature-preview {
  padding: 0 15px;

  @media (min-width: $screen-lg-min) {
    padding: 0 105px;
  }

  .document-page {
    box-shadow: 2px 3px 5px 4px #999;
    padding: 20px 30px 20px 30px;
    font-family: serif;
    font-size: 15px;
  }

  .signature-container {
    margin-top: 15px;
    margin-right: 15px;
    text-align: right;
    svg {
      height: 40px;
      width: auto;
    }
  }

  .buttons-container {
    margin-top: 30px;
  }
}

#svgContainer {
  visibility: hidden;
  height: 5px;
  width: 5px;
}

.signature-buttons-container {
  .btn.btn-danger {
    background-color: #e05053;
    border-color: #ea5b5e;
  }

  .btn.btn-link {
    padding: 3px 0;
  }

  // on mobile
  @media (max-width: $screen-xs-max) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 10px;
    -webkit-box-shadow: 0 -5px 9px #eee;
    box-shadow: 0 -5px 9px #eee;
    background-color: #fff;
  }

  // on mobile in horizontal position
  @media (min-width: $screen-mobile-horizontal-min) and (max-width: $screen-xs-max) {
    padding: 8px;

    .form-group {
      margin-bottom: 5px;
    }

    .btn.btn-link {
      padding: 0;
    }

    .list-unstyled {
      margin-bottom: 0;

      > li {
        display: inline-block;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  // on tablets and larger
  @media (min-width: $screen-sm-min) {
    margin-top: 20px;
  }
}

.signature-input-mode {
  @media (max-width: $screen-xs-max) {
    main {
      padding-bottom: 200px;
    }

    header {
      display: none;
    }

    footer {
      display: none;
    }
  }

  @media (min-width: $screen-mobile-horizontal-min) and (max-width: $screen-xs-max) {
    main {
      padding-bottom: 85px;
    }
  }
}
