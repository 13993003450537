.instruction-intro-heading
{
  font-weight: bold;
  font-size: 16px;
  color: #868686;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

h1.instruction-heading
{
  font-size: 68px;
  margin-top: 5px;

  @media (max-width: $screen-xs-max) {
    font-size: 48px;
  }
}

.instruction-subheading {
  font-size: 20px;
  margin: auto;
  max-width: 530px;
}



.instruction-container {
  margin-top: 25px;
  p {
    font-size: 20px;
  }

  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.instruction-text{
  margin-top: 80px;

  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
}

.instruction-image {
  border: solid 10px #f1f1f1;
  width: 100%;
  max-width: 400px;
}

.instruction-number {
  width: 60px;
  height: 60px;
  margin-top: -15px;
  margin-left: -80px;
  float: left;
  font-size: 34px;
  padding-top: 0;
  text-align: center;
  border-radius: 60px;
  background: #fbe9c4;

  @media (max-width: $screen-xs-max) {

    margin: auto;
    float: none;
    display: block;
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.instruction-title {
  margin-top: 45px;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 600;
  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
}