header {
  position: relative;
  .logo {
    margin: 20px 0;
    height: 60px;
    @media screen and (max-width: 767px) {
      height: auto;
      margin: 10px 0;
      min-height: 40px;
      .logo-text {
        float: none !important;
        padding-left: 0 !important;
        margin: 0;
      }
      img {
        position: absolute;
        top: -20px;
        left: -20px;
        width: 120px !important;
        opacity: 0.3;
      }
    }
    img {
      float: left;
      width: 60px;
    }
    .logo-title {
      color: #000;
      font-size: 22px;
      font-weight: bold;
    }

    .logo-text {
      padding-left: 15px;
      float: left;
    }
  }
  .navbar-toggle {
    float: right;
    margin: 0;
    width: 50px;
    height: 40px;
    background: #eee;
    position: relative;
    .icon-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 30px;
      margin: auto;
      background: #003f58;
      transition: 0.4s;
      &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        background: inherit;
        width: 100%;
        height: 2px;
        transition: 0.4s;
      }
      &::before {
        top: 8px;
      }
      &::after {
        bottom: 8px;
      }
    }
  }

  .navbar-nav > li {
    > a > svg {
      fill: #000;
    }
    > a:hover > svg {
      fill: #333;
    }
  }

  @media screen and (min-width: 767px) {
    .navbar-nav > li > a {
      border-right: solid 1px $border-color;
      font-weight: bold;
    }
    .navbar-nav.navbar-right > li > a {
      border-left: solid 1px $border-color;
      border-right: 0;
      font-weight: bold;
    }
    .nav > li > a {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .navbar {
      min-height: 0;
    }
    .navbar-default {
      border-width: 0;
    }
  }
}