.step {

  padding: 20px 30px;
  color: #000;

  &.step-1 {
    background: rgba(0, 122, 0, 0.05);
    margin-right: 15%;

    @media (max-width: $screen-xs-max) {
      margin-right: 0;
    }
  }

  &.step-2 {
    background: rgba(0, 122, 0, 0.10);
    margin-right: 10%;

    @media (max-width: $screen-xs-max) {
      margin-right: 0;
    }

  }

  &.step-3 {
    background: rgba(0, 122, 0, 0.15);
    margin-right: 5%;

    @media (max-width: $screen-xs-max) {
      margin-right: 0;
    }
  }

  &.step-4 {
    background: rgba(0, 122, 0, 0.20);
  }
}

