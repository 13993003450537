.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}

.row-no-padding>[class*=col-] {
  padding-left: 0!important;
  padding-right: 0!important;
}


/**
 * A rectangle image only shown on the mobile version
 *
 * Try to have the image in the following size:
 * 690x400 retina (345x200)
 *
 */
.img-mobile {
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

/**
 * A circle image only shown on tablet and desktop
 * On the mobile version, this should be hidden and preferable replaced by an image with the class .img-mobile
 *
 * Try to have the image in the following size:
 * 600x600 retina (300x300)
 *
 */
.page-img-desktop
{
  width: 300px;
  height: 300px;
  border-radius: 150px;
  margin: 0 auto 40px;
  display: block;

  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

/**
 * Remove the breadcrumbs margin to the H1 on mobile version
 */
.breadcrumb {
  @media (max-width: $screen-xs-min) {
    margin-bottom: 0;
  }
}


/**
 * Present our clients with a logo
 *
 * The image should have the height 180 px retina (90px)
 *
 */
.testimonial-logo {
  height: 90px;
  margin: 0 40px 20px 0;
}


.radio-box {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 3px;
  background: #ffffff;
  border: solid 1px #e5e6eb;
  margin: 3px 0 3px;
  cursor: pointer;
}

.radio-box-inline
{
  margin-bottom: 10px;
  margin-right: 6px;
}


.radio-box:hover
{
  background: #f1f1f1;
}

.radio-box input {
  margin-right: 15px;
  float: left;
}

.radio-box.active {
  background: $alert-success-bg;
  border-color: $alert-success-border;
  border-width: 1px;
}

.buy-subscription {
  margin-bottom: 20px;

  p {
    line-height: 1.2em;
    font-size: 1.15em;
  }

  .btn-primary {
    font-size: 1.35em;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    padding: 10px;
    line-height: 1.35em;
    background: #f3d078;
    background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
    background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    border-color: #f0c14b;

    &:hover, &:active, &:focus {
      background: #f1c860;
      background: -webkit-linear-gradient(top,#f5d78e,#eeb933);
      background: linear-gradient(to bottom,#f5d78e,#eeb933);
      box-shadow: none;
    }
  }
}

.pull-item-left {
  float: left;
  width: 40px;
  margin-left: -40px;

}

/**
 * Modify the bg-warning background
 */
.bg-warning
{
  background: #fefde6;
}

.bg-padding
{
  padding: 15px 20px 5px;
  border-radius: 10px;
  margin-bottom: 20px;
}



.framed-box {
  font-size: 14px;
  border: 2px solid #e6e6e6;
  border-left-width:60px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #fff;
}

.framed-box-input {
  float: left;
  text-align: center;
  margin: 20px 0 0 -35px;
}

.padding
{
  padding: 15px 20px;
}

.btn-link
{
  text-decoration: underline;
}
