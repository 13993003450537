@media (min-width: $screen-sm-min) {
  #input-extracts {
    max-width: 160px;
  }
}


#pricing-page-promo
{
  border-radius: 200px;
  width: 200px;
  height: 200px;
  background: #d3dde4;
  color: #1f5678;
  margin-top: 40px;
  margin-left: 20px;
  transform: rotate(7deg);
  text-align: center;
  padding: 64px 40px 0;
  float: right;
  font-size: 20px;

}


@media (max-width: $screen-sm-max) {

  #pricing-page-promo
  {
    border-radius: 160px;
    width: 160px;
    height: 160px;
    margin-top: 0;
    margin-left: 10px;
    transform: rotate(7deg);
    padding: 36px 20px 0;
    float: right;
    font-size: 18px;

  }
}



