
// Body
$body-bg: #ffffff;
$border-color: #cbcbcb;
$bg-grey: #f1f1f1;


// Borders
$list-group-border: $border-color;
$navbar-default-border: $border-color;
$panel-default-border: $border-color;
$panel-inner-border: $border-color;
$navbar-default-link-color: #000;
$navbar-default-link-active-color: #000;

// Brands
$brand-primary: #f7bc2f;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: $bg-grey;
$navbar-border-radius: 0;
$navbar-padding-horizontal: 0;


// Buttons
$btn-default-color: $text-color;
$btn-border-radius-base: 2px;
$btn-border-radius-large: 2px;
$btn-primary-color: #000;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$headings-color: #003f58;
$link-color: #297595;

// add mobile horizontal grid option
$screen-mobile-horizontal-min: 500px;


// Breadcrumbs
$breadcrumb-padding-vertical: 0;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-separator: '›';
$breadcrumb-bg: none;