/*
   These styles are also used on landing page.
 */
.vertical-article-card {
  display: block;
  color: $text-color;
  margin-bottom: 20px;
  background: $bg-grey;

  &:hover, &:focus {
    color: $text-color;
    text-decoration: none;

    h3 {
      text-decoration: underline;
    }
  }

  &-image {
    width: 100%;
  }

  &-text {
    padding: 15px;

    div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h4 {
      font-size: 17px;
      margin-top: 3px;
      margin-bottom: 8px;
    }
  }
}