.advantage {
  padding-left: 55px;
  padding-bottom: 20px;
  background: url(/images/icons/success.svg) no-repeat;
  background-size: 27px 27px;
  background-position: 0 0;

  h3 {
    margin-top: 15px;
  }

  p {
    font-size: 15px;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .advantage {
    padding-left: 45px;
  }
}


.contact-me-form {
  border: 2px solid #b2cabd;
  padding: 15px 36px;
  &.affix {
    position: static;
  }

  @media (min-width: $screen-lg-min) {
    margin-left: 30px;
    &.affix {
      position: fixed;
      width: 330px;
      top: 35px;
    }

    &.affix-bottom {
      position: absolute;
      width: 330px;
    }
  }

  h3 {
    color: #319b65;
    margin-bottom: 17px;
  }

  .btn-primary {
    padding: 8px 14px;
    font-size: 15px;
    line-height: 1.25;
    margin-top: 12px
  }
}

.contact-me-panel-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 10px;
  -webkit-box-shadow: 0 -5px 9px #eee;
  box-shadow: 0 -5px 9px #eee;
  background-color: #fff;
}

footer .footer.padded-footer-on-mobile {
  @media (max-width: $screen-xs-max) {
    padding-bottom: 80px;
  }
}