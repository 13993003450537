// Fonts
// Fonts should always be loaded separately, directly via the HTML-request for performance reasons
// @import "node_modules/font-awesome/scss/font-awesome";

// Variables
@import "variables";

// Bootstrap
@import "bootstrap-custom";

// Errors
@import "errors";

// Partials
@import "text";
@import "partials/header";
@import "partials/index";
@import "partials/footer";
@import "partials/contact-me-modal";
@import "partials/startpage";
@import "partials/article";
@import "partials/how-it-works";
@import "partials/service-page";
@import "partials/service";
@import "partials/web-signature";
@import "partials/steps";
@import "partials/question";
@import "partials/progress-bar";
@import "partials/pricing-page";
@import "partials/plan";


// include grid rules for horizontal mobile orientation
@import "mobile-horizontal";

@import "miscellaneous";

