.plan-box {
  padding: 20px 15px;
  font-size: 14px;
  color: #666;
  text-align: center;

  border-radius: 5px;
  background: #fff;

  border: 2px solid #e6e6e6;
  margin-bottom: 10px;
}

.plan-most-popular {
  color: #2ab27b;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

@media (max-width: $screen-sm-max) {

  .plan-most-popular {
    display: none;
  }

}

.plan-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.plan-price {
  font-size: 36px;
  font-weight: bold;
  color: #000000;
}

.plan-unit {
  margin-bottom: 10px;
  color: #000000;
}


.plan-offer {
  color: red;
  height: 20px;
  margin-bottom: 10px;
}

.plan-box hr {
  border-color: #e6e6e6;
  border-style: solid;

}

.plan-box.active
//.plan-box:hover
{
  border-color: #2ab27b;
  border-width: 4px;
  background: #e9f7f2;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  padding: 18px 13px;
}
