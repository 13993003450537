.progress
{
  max-height: 16px;
  background: #d8d8d8;
  border-radius: 4px;


  @include box-shadow(0);
}

.progress-bar-success
{
  background: #207720;
  background: -moz-linear-gradient(left, #207720 0%, #089408 100%);
  background: -webkit-linear-gradient(left, #207720 0%,#089408 100%);
  background: linear-gradient(to right, #207720 0%,#089408 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207720', endColorstr='#089408',GradientType=1 );
}