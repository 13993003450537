/**
 * Tiny heading in green used to explain a section
 */
.explain-heading {
  font-size: 13px;
  font-weight: bold;
  color: darkgreen;
  margin: 0 0 8px 0;
}


/**
 * Grey box
 */
.box
{
  margin-bottom: 20px;
  background: $bg-grey;
  padding: 20px 25px;

  &-heading{
    margin-top: 0;
  }
}




/**
 * A grey "read-more" box
 */
.service {
  margin-bottom: 20px;
  background: $bg-grey;

  &:hover {
    background: darken($bg-grey, 0.2);
  }


  @media(max-width: $screen-xs-max) {
    min-height: 148px;
  }

  @media(max-width: 500px) {
    min-height: 101px;
  }


  /**
   * The image should be wide-screen (16:9)
   *
   * For desktop shown as 263x148px
   * For mobile, 345x194px (690x388px for retina)
   *
   */
  &-image {
    width: 100%;
  }

  &-text {
    padding: 20px 25px;

    /* Present the link above the image on the mobile version */
    @media(max-width: $screen-xs-max) {
      padding: 8px 10px;
      position: absolute;
      margin: -50px 10px 0;
      z-index: 100;
      background: #fff;
    }


  }




  h3 {
    margin-top: 8px;

    @media(max-width: $screen-xs-max) {

      margin: 0;

      &:after {
        content: " ›";
      }

    }


  }


  p {
    font-size: 15px;
    margin-bottom:0;

    @media(max-width: $screen-sm-max) {
      display: none;
    }
  }
}

a.service {
  display: block;
  color: $text-color;

  &:hover, &:focus {
    color: $text-color;
    text-decoration: none;

    h3 {
      text-decoration: underline;


    }

    @media(max-width: $screen-sm-max) {
      h3 {
        text-decoration: none;
      }

      .service-text{
        background: darken($bg-grey, 0.2);
      }

    }

  }
}
