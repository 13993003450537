h1 {
  font-weight: bold;
}

a {
  color: $link-color;
}

// Make a blue link even more clear that it's a link
a.emphasise {
  text-decoration: underline;
}



.bg-unread {
  background: #d8ecfc;
}

