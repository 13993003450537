.col-mobile-horizontal-1, .col-mobile-horizontal-2, .col-mobile-horizontal-3, .col-mobile-horizontal-4, .col-mobile-horizontal-5,
.col-mobile-horizontal-6, .col-mobile-horizontal-7, .col-mobile-horizontal-8, .col-mobile-horizontal-9, .col-mobile-horizontal-10,
.col-mobile-horizontal-11, .col-mobile-horizontal-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: $screen-mobile-horizontal-min) and (max-width: $screen-xs-max) {
  .col-mobile-horizontal-1, .col-mobile-horizontal-2, .col-mobile-horizontal-3, .col-mobile-horizontal-4, .col-mobile-horizontal-5,
  .col-mobile-horizontal-6, .col-mobile-horizontal-7, .col-mobile-horizontal-8, .col-mobile-horizontal-9, .col-mobile-horizontal-10,
  .col-mobile-horizontal-11, .col-mobile-horizontal-12 {
    float: left;
  }

  .col-mobile-horizontal-1 {
    width: 8.33333333%;
  }

  .col-mobile-horizontal-2 {
    width: 16.66666667%;
  }

  .col-mobile-horizontal-3 {
    width: 25%;
  }

  .col-mobile-horizontal-4 {
    width: 33.33333333%;
  }

  .col-mobile-horizontal-5 {
    width: 41.66666667%;
  }

  .col-mobile-horizontal-6 {
    width: 50%;
  }

  .col-mobile-horizontal-7 {
    width: 58.33333333%;
  }

  .col-mobile-horizontal-8 {
    width: 66.66666667%;
  }

  .col-mobile-horizontal-9 {
    width: 75%;
  }

  .col-mobile-horizontal-10 {
    width: 83.33333333%;
  }

  .col-mobile-horizontal-11 {
    width: 91.66666667%;
  }

  .col-mobile-horizontal-12 {
    width: 100%;
  }


  .col-mobile-horizontal-offset-0 {
    margin-left: 0%;
  }

  .col-mobile-horizontal-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-mobile-horizontal-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-mobile-horizontal-offset-3 {
    margin-left: 25%;
  }

  .col-mobile-horizontal-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-mobile-horizontal-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-mobile-horizontal-offset-6 {
    margin-left: 50%;
  }

  .col-mobile-horizontal-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-mobile-horizontal-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-mobile-horizontal-offset-9 {
    margin-left: 75%;
  }

  .col-mobile-horizontal-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-mobile-horizontal-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-mobile-horizontal-offset-12 {
    margin-left: 100%;
  }
}
