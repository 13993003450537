body{
  display: flex;
  margin: 0;
}

#app {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  min-width: 0;
  main{
    flex: 1 1 auto;
  }
}


//style for custom button
.btn {
  &-block-xs {
    @media screen and (max-width: 767px) {
      min-width: 100%;
      display: block;
      position: relative !important;
    }
  }
  &-download {
    margin-top: 22px;
    margin-bottom: 11px;
  }
  &-grey {
    background: #ccc;
    color: #414141;
  }
  &-lg-not-xs {
    @media screen and (min-width: 767px) {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
      border-radius: 2px;
    }
  }
}

//align teg p to justify in .text-justify-p
.text-justify-p p {
  text-align: justify;
}

//always display block
.d-block {
  display: block !important;
}

//text align left when screen 991
.text-left-sm {
  @media screen and (max-width: 991px) {
    text-align: left !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
}

//list orders
.orders {
  .disabled * {
    background: #eee;
    color: #414141;
    .progress-bar {
      background: #dddddd;
    }
    a.btn {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.65;
      box-shadow: none;
    }
  }
}

//user order show
.order {
  img {
    max-width: 100%;
    min-width: 100%;
  }
}

.checkboxStyle, .radioStyle {
  position: relative;
  width: 22px;
  height: 22px;
  min-height: inherit !important;
  input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    &:checked + label {
      &::before {
        opacity: 1;
      }
    }
  }
  label {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    border: 1px solid #414141;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    z-index: 1;
    &::before {
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      transition: 0.2s;
      opacity: 0;
    }
  }
  input[type=checkbox] + label{
    border-radius: 3px;
    &::before{
      content: "\e013";
    }
  }
  input[type=radio] + label{
    border-radius: 50%;
    &::before{
      content: "";
      background: #414141;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.help {
  position: absolute;
  left: 100%;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  border: 1px solid #eee;
  font-family: 'Arial';
  &::before {
    content: '?';
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
  &:hover > .help-text {
    bottom: 100%;
    left: 100%;
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
  &-text {
    position: absolute;
    transition: 0.2s;
    min-width: 100px;
    font-size: 12px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 0px 5px;
    margin: 5px;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    left: 0;
  }
}

.preselect-container {
  margin-bottom: 30px;

  .open > .btn-default.dropdown-toggle {
    background-color: #fff;
    border-color: #ccc;
  }

  .preselect-dropdown {
    .dropdown-toggle {
      width: 100%;
      text-align: right;
      padding: 0;
      &.btn-default:active, &.btn-default:focus, &.btn-default:hover {
        background-color: #fff;
        border-color: #ccc;
        box-shadow: none;
        outline: 0;
      }

      .button-text {
        padding: 6px 12px;
      }

      .caret-container {
        background-color: #e5e5e5;
        position: absolute;
        right: 1px;
        top: 1px;
        width: 30px;
        height: 34px;
        text-align: center;

        .caret {
          vertical-align: -20%;
        }
      }
    }

    .dropdown-menu {
      width: 100%;
      margin: 0;
      padding: 8px 0;
      > li > a {
        padding: 3px 12px;
        overflow: hidden;
      }
    }
  }

}


