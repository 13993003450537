.contact-me-modal {
  .success-message {
    padding: 30px 30px 70px 30px;
  }

  .close {
    font-size: 30px;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 500px;
    }
  }
}