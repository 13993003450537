footer {
  .footer {
    margin-top: 200px;
    background: #efefef;
    padding: 60px 0;
    box-sizing: border-box;
    .footer {
      &-logo {
        img {
          float: left;
          width: 50px;
          margin-right: 15px;
        }
        &-title {
          padding-left: 65px;
          a {
            color: #000;
            font-size: 20px;
          }
        }
        &-text {
          margin-left: 65px;
        }
      }
    }
    &-menu {
      padding: 5px 0 0;
      margin: 0;
      list-style: none;
      @media screen and (max-width: 991px) {
        margin-left: 65px;
      }
      li {
        line-height: 25px;
      }
    }
  }

  .admin-footer {
    padding: 70px 0;
  }
}