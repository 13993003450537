.question {
  border-radius: 10px;
  border: solid 1px $border-color;
  margin-bottom: 10px;
  user-select: none;

  &-title {
    font-weight: bold;
    font-size: 16px;
    margin: 15px 20px;
    display: block;
    cursor: pointer;
  }

  &-answer {
    display: none;
    margin: 0 20px 15px;
  }

}

.question.active {

  background: $bg-grey;
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);

  .question-answer {
    display: block;
  }

}