.start-page-splash
{
  padding: 42px 0 22px;
  margin: -22px 0;
}

@media(min-width: $screen-md-min){
  .start-page-splash{
    background: url(/images/gfx/hr-chef-board.jpg) top right no-repeat;
    background-size: contain;
  }
}