@import "vertical-article-card";


.article-image {
  width: 100%;
  margin: 40px 0;
}

.article-ingress {
  font-size: 20px;
}

.article-text
{
  font-family: 'Merriweather', serif;
  font-size: 18px;
  color: #000;
  font-weight: 300;
  line-height: 30px;

  h3{
    font-weight: bold;
    color:#000;
    font-family: $font-family-base;
  }
}



/*
 * Present a list of articles
 */
.article-preview {
  padding: 0 0 0 200px;
  min-height: 90px;
  margin-bottom: 20px;
  background: no-repeat left top;
  background-size: 160px;
  cursor: pointer;
  display: block;
  color: $text-color;

  &:hover, &:focus {
    color: $text-color;
    text-decoration: none;
    h4 {
      text-decoration: underline;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 140px;
    background-size: 120px;
    h4 {
      font-size: 16px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }
}

.article-card {
  color: $text-color;
  margin-bottom: 20px;
  display: block;

  &:hover, &:focus {
    color: $text-color;
    text-decoration: none;
  }

  >.row-no-padding>div:first-child {
    padding-right: 25px !important;
    img {
      width: 100%;
    }
  }

  @media (min-width: $screen-sm-min) {
    .card-content {
      height: 130px;
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 52px;
        width: 100%;
        background: linear-gradient(rgba(255, 255, 255, 0), #fff);
      }
    }
  }
}
