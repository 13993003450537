.error-page {
  .logo {
    display: table;
    margin: 0 auto;

    > div {
      display: table-cell;
    }
  }

  @media screen and (max-width: 767px) {
    .error-text {
      margin-top: 120px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 150px;
    h2 {
      margin-top: 25px;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 250px;
  }
}